angular.module('app').filter('mobile', function () {
    return function (mobile) {
        mobile = mobile || '';
        mobile = mobile.toString();
        mobile = mobile.trim();
        mobile = mobile.replace(/\D/, '');
        var first = mobile.length >= 4 ? mobile.substring(0, 4) : '';
        var second = mobile.length >= 7 ? ' ' + mobile.substring(4, 7) : '';
        var third = mobile.length >= 10 ? ' ' + mobile.substring(7, 10) : '';
        return first + second + third;
    };
});